/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Tabs, Tab } from 'react-bootstrap';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesJudi: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Primal Lord - Nocturne Judicator</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_primaljudi.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Primal Lord - Nocturne Judicator</h1>
          <h2>
            Primal Lord - Nocturne Judicator guide that gives you the best teams
            to use against the boss.
          </h2>
          <p>
            Last updated: <strong>05/Mar/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Nocturne Judicator" />
        <p>
          <strong>Nocturne Judicator</strong> is one of the two Primal Lords
          introduced in the Season 3 of AFK Journey. In this guide you will find
          the best teams to use against him!
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.{' '}
          <strong className="gold">
            Try running Dual carry, as currently there is no way to boost a
            Hypercarry’s damage high enough to make it worth running only one
          </strong>
          . Keep faction bonus in mind but don’t run suboptimal characters just
          to force it.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Best teams" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Best Team</>}
            className="with-bg"
          >
            <h5>Team #1 - Best Team (Whale)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/judi_1.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>E-Nlinhhh(Afkjvietnam)</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="elijah-and-lailah"
                  enablePopover
                />{' '}
                are used for Haste and buffs for Shakir (<strong>S/S+</strong>{' '}
                required).
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="sinbad" enablePopover /> can
                  be used as alternative.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="shakir" enablePopover /> acts
                as buffer.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="mikola" enablePopover /> can
                  be used as an alternative
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>S/S+</strong> required).
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="kruger" enablePopover /> can
                  be used as an alternative
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />{' '}
                acts as main damage carry
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="sonja" enablePopover /> can
                  be used as an alternative
                </li>
              </ul>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                act as healers and buffer.
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Bladesummon Spell" /> - Best overall
                  performance.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team2"
            title={<>Team #2 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #2 - Alt Team (S+ Twins)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/judi_2.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Puzzle AFK and Muffyn(Afkjvietnam)</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="elijah-and-lailah"
                  enablePopover
                />{' '}
                are used for Haste and buffs for Shakir (<strong>S/S+</strong>{' '}
                required).
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="sinbad" enablePopover /> can
                  be used as alternative.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="shakir" enablePopover /> acts
                as buffer.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="mikola" enablePopover /> can
                  be used as an alternative
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="kruger" enablePopover /> acts
                as debuffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as buffer.
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                act as healers and buffer.
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Bladesummon Spell" /> - Best overall
                  performance.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team3"
            title={<>Team #3 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #3 - Alt Team (One Copy Twins)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/judi_3.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Rymz(Afkjvietnam)</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="elijah-and-lailah"
                  enablePopover
                />{' '}
                are used for Haste and buffs for Shakir (
                <strong>Works at 1 Dupe</strong> required).
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="sinbad" enablePopover /> can
                  be used as alternative.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="shakir" enablePopover /> acts
                as buffer.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="mikola" enablePopover /> can
                  be used as an alternative
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="kruger" enablePopover /> acts
                as debuffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as buffer.
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                act as healers and buffer.
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Bladesummon Spell" /> - Best overall
                  performance.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team4"
            title={<>Team #4 - No Celehypo</>}
            className="with-bg"
          >
            <h5>Team #4 - No Celehypo</h5>
            <StaticImage
              src="../../../images/afk/dream_s/judi_4.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Eri (Prydwen)</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="sinbad" enablePopover /> acts
                as debuffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="shakir" enablePopover /> acts
                as buffer.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="mikola" enablePopover /> can
                  be used as alternative.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="kruger" enablePopover /> acts
                as debuffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as buffer.
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                acts as healer and buffer.
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Bladesummon Spell" /> - Best overall
                  performance.
                </li>
              </ul>
            </ul>
          </Tab>
        </Tabs>
        <SectionHeader title="Videos" />
        <p>Coming soon!</p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesJudi;

export const Head: React.FC = () => (
  <Seo
    title="Nocturne Judicator | AFK Journey | Prydwen Institute"
    description="Primal Lord Nocturne Judicator guide that gives you the best teams to use against the boss."
    game="afk"
  />
);
